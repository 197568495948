import { useEffect, useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import Button from "../../components/ControlElements/Button/index.jsx";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import config from "../../config.js";
import { capitalizeFirst, getProjectAge, getProjectLink, getScanLink, getSocialLink, isProjectFresh, isProjectUpcoming } from "../../utils/basicHelpers.js";
import CustomChart from "../../components/CustomChart/index.jsx";
import { getLogoURL } from "../../utils/imageHelpers.js";
import Loader from "../../components/Loader/index.jsx";
import CountdownTimer from "../../components/CountdownTimer/index.jsx";
import SecurityMarks from "./SecurityMarks.js";
import StatsTable from "./StatsTable.js";
import TransactionView from "./TransactionsView.js";
import TopUsersView from "./TopUsersView.js";

import "./Page.scss"
import Icon from "../../components/ControlElements/Icon/index.jsx";
import WarningLink from "../../components/WarningLink/index.jsx";
import AdsBanner from "../../components/AdsBanner/index.jsx";

function Page(props) {
  const [project, getProject] = useState(null);
  const { identifier } = useParams();
  useEffect(() => {
    fetch(config.serverEndpoint + "project/" + identifier + "/full")
      .then((response) => response.json())
      .then((data) => getProject(data.payload))
      .catch((error) => console.log(error));
  }, [identifier]);
  if (project) {
    project.isUpcoming = isProjectUpcoming(project.launch_date);
    project.isDead = (project.TVL.TVLUSD < 3 && getProjectAge(project.launch_date) > 3);
    return (
      <Container className="page">
        <Helmet>
          <title> {project.name} project on Orientier #1</title>
        </Helmet>
        <div className="o-block py-4 px-3">
          <Row>
            <Col xs={4} md={2}>
              <WarningLink href={getProjectLink(project)}>
                <img className="logo mt-2" src={getLogoURL(project?.id)} />
              </WarningLink>
              <p className="d-flex justify-content-around">{project?.social &&
                project?.social.map((element) => {
                  return (
                    <WarningLink href={getSocialLink(element.name) + element.value} target="_blank">
                      <Icon type={element.name} stuff={true} />
                    </WarningLink>
                  );
                })}</p>
            </Col>
            <Col xs={8} md={6}>
              {!project.isDead && project.active == 1
                ? <>
                  <h1>
                    <WarningLink href={getProjectLink(project)}>
                      {project?.name}
                    </WarningLink>{project.isFresh && <Badge className="bg-success">Fresh</Badge>}
                    <p className="out-buttons">
                      <Button href={getScanLink(project?.network.slug) + "address/" + project?.contract_address} target="_blank">Contract </Button>
                      <WarningLink href={getProjectLink(project)}>
                        <Button nohref={true}>DAPP</Button>
                      </WarningLink>
                    </p>
                  </h1>
                  <p>{project.description
                    ?
                    project.description
                    : "No description provided"
                  }</p>
                  <p className="security-marks"><SecurityMarks marks={project?.marks} /></p>
                  <p className="audits-buttons">
                    {project?.audits &&
                      project?.audits.map((element) => {
                        return (
                          <Button href={element.link} target="_blank" ><Icon type="audit" /> {capitalizeFirst(element.name)} </Button>
                        );
                      })}
                  </p>
                </>
                : project.isDead
                  ? <h2>Project is dead</h2>
                  : <>
                    <h2>Project archived <Button href={getScanLink(project?.network.slug) + "address/" + project?.contract_address} target="_blank">Contract </Button></h2>
                  </>
              }
            </Col>
            <Col xs={12} md={4}>
              <AdsBanner name="project-page-right" />
              <StatsTable project={project} />
            </Col>
          </Row>
          {!project.isUpcoming &&
            <Row>
              <Col xs={12} lg={6}>
                <CustomChart chartData={[{ data: project?.TVLChart?.data, key: "sumUSD", as: "TVL USD" }]} />
              </Col>
              <Col xs={12} lg={6}>
                <CustomChart
                  shortToolTip={true}
                  chartData={[
                    { data: project?.depositsChart?.data, key: "sumUSD", as: "Deposits USD" },
                    { data: project?.withdrawalsChart?.data, key: "sumUSD", as: "Withdrawals USD" },
                  ]}
                />
              </Col>
            </Row>
          }
        </div>
        <div className="o-block py-4 px-3">
          {project.isUpcoming
            ? <CountdownTimer to={project.launch_date} />
            : <>
              <Row>
                <Col lg={6}>
                  <h3>Latest deposits</h3>
                  <TransactionView transactions={project?.transactions?.latestDeposits} project={project} />
                </Col>
                <Col lg={6}>
                  <h3>Latest withdrawals</h3>
                  <TransactionView transactions={project?.transactions?.latestWithdrawals} project={project} />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <h3>Top depositors</h3>
                  <TopUsersView data={project?.users?.topDepositors} project={project} type="deposited" />
                </Col>
                <Col lg={6}>
                  <h3>Top gainers</h3>
                  <TopUsersView data={project?.users?.topGainers} project={project} type="gained" />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container >

    );
  } else {
    return (<Loader style={{ marginTop: "30vh" }} />);
  }
}

export default Page;
