import React, { useMemo, useState, useEffect } from "react";
import { Container, Nav, Row, Col, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { LinkContainer } from "react-router-bootstrap";
import { MaterialReactTable } from "material-react-table";

import { getNetworkLink, isProjectUpcoming, getSocialLink, getProjectAge, getProjectLink } from "../../utils/basicHelpers";
import { getLogoURL, getNetworkImageURL, getSecurityIcon, getTokenImageURL } from "../../utils/imageHelpers";
import { getTvlChangeColor, getFeeColor } from "../../utils/colorHelpers";

import FavoriteIcon from "./FavoriteIcon";
import Icon from "../../components/ControlElements/Icon/index.jsx";
import MyCustomChart from "../../components/CustomChart";
import Loader from "../../components/Loader";
import CountdownTimer from "../CountdownTimer";
import Button from "../ControlElements/Button";
import config from "../../config";
import WarningLink from "../WarningLink";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse.js";

function TableDesktop(props) {
  useEffect(() => {
    setData(props.data);
    setSorting([]);
  }, [props]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "favorite",
        header: <Icon type="favorite" />,
        enableSorting: false,
        size: 10,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => <FavoriteIcon id={row.original.id} style={{ cursor: "pointer" }} />,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>
              <img
                alt="logo"
                className="project-logo"
                height={50}
                width={50}
                src={getLogoURL(row.original.id)}
                loading="lazy"
                style={{ borderRadius: "50%" }}
              />
            </Box>
            <Box>
              <LinkContainer to={"/project/" + row.original.slug + "/"}>
                <Nav.Link className="table_desktop__name_link">{row.original.name}
                  {(row.original.isFresh && !row.original.sortWeight < 128 && !row.original.isUpcoming) && <Badge className="bg-success">Fresh</Badge>}
                  {(row.original.isUpcoming && <Badge bg="secondary">coming</Badge>)}
                  {(row.original.sortWeight > 128 && <Badge className="bg-promo">Promo</Badge>)}
                  {row.original.isDead && <Badge bg="danger">Dead</Badge>}
                </Nav.Link>
              </LinkContainer>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: "chain",
        header: "Chain / Coin",
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },

        Cell: ({ row }) => (
          <Box>
            <img
              alt="network"
              height={25}
              src={getNetworkImageURL(row.original.chain)}
              loading="lazy"
              title={row.original.chainName}
              style={{ borderRadius: "50%" }}
              className="me-1"
            />
            <img
              alt="coin"
              height={25}
              src={getTokenImageURL(row.original.token)}
              title={row.original.tiker}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
          </Box>
        ),
      },
      {
        accessorKey: "security",
        header: "Security",
        size: 130,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          <Box>
            {row.original.securityMarks?.map((item, index) => {
              let tooltipText;
              switch (item.name) {
                case "haze":
                case "hazecrypto":
                  tooltipText = "Audited by HazeCrypto"
                  break
                case "solidproof":
                  tooltipText = "Audited by Solid Proof"
                  break
                case "cryptoprim":
                  tooltipText = "Audited by Cryptographic Primitive"
                  break
                case "reviewed":
                  tooltipText = "Contract source code reviewed by Orientier team"
                  break
                case "UNAUDITED":
                  tooltipText = "This project is not audited by any trusted auditor company"
                  break
                case "safu-dev":
                  tooltipText = "Trusted admin"
                  break
                case "info":
                  tooltipText = "Additional information available on project page"
                default:
                  tooltipText = item.description == null ? item.name : item.description;
              }
              const tooltip = (
                <Tooltip id="tooltip">
                  {tooltipText}
                </Tooltip>
              )
              return (
                <a href={item.link} target="_blank" className="pfff me-1">
                  <OverlayTrigger placement="top" overlay={tooltip}>
                    <img src={getSecurityIcon(item.name)} alt={item.name} width={25} />
                  </OverlayTrigger>
                </a>
              )
            })}
          </Box>
        ),
      },
      {
        accessorKey: "age",
        header: "Age",
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          row.original.isUpcoming
            ?
            <CountdownTimer to={row.original.launch_date} headline={false} micro={true} />
            :
            <Box>{row.original.age == "started"
              ? "started today"
              : <>{row.original.age} days</>
            } </Box>
        )
      },
      {
        accessorKey: "daily",
        header: "Daily",
        size: 90,
      },
      {
        accessorKey: "tvl",
        header: "TVL",
        size: 95,
        Cell: ({ row }) => (
          <>
            <Box>
              {!row.original.isUpcoming
                ? "$" + row.original.TVLUSD
                : <Badge bg="secondary">coming</Badge>

              }</Box>
            {row.original.tiker != "USDT" ? (
              <span className="real-tvl-tip" style={{ fontSize: "x-small" }}>
                {row.original.isUpcoming
                  ? ""
                  : row.original.TVL + " " + row.original.tiker
                }
              </span>
            ) : null}
          </>
        ),
      },
      {
        accessorKey: "tvl24",
        header: "TVL 24h",
        size: 20,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          <Box>
            {!row.original.isUpcoming && !row.original.isDead
              ? <div><span className={getTvlChangeColor(row.original.tvl24)}>{row.original.tvl24 > 0
                ? "+" + row.original.tvl24
                : row.original.tvl24
              }%</span></div>
              : row.original.isUpcoming
                ? <Badge bg="secondary">coming</Badge>
                : <Badge bg="danger">dead</Badge>
            }
          </Box>
        ),
      },
      {
        accessorKey: "tvl7",
        header: "TVL 7d",
        size: 20,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          <Box>
            {!row.original.isUpcoming && !row.original.isDead
              ? <div><span className={getTvlChangeColor(row.original.tvl7d)}>{row.original.tvl7d > 0
                ? "+" + row.original.tvl7d
                : row.original.tvl7d
              }%</span></div>
              : row.original.isUpcoming
                ? <Badge bg="secondary">coming</Badge>
                : <Badge bg="danger">dead</Badge>
            }
          </Box>
        ),
      },
      {
        accessorKey: "fees",
        header: "Fees",
        size: 10,
        maxSize: 20,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          <Box>
            <div className={getFeeColor(parseFloat(row.original.feesIn) + parseFloat(row.original.feesOut))}>
              <span>{row.original.feesIn}%</span>
              &nbsp;/&nbsp;
              <span>{row.original.feesOut}%</span>
            </div>
          </Box>
        ),
      },
      {
        id: "link",
        header: "Link",
        enableSorting: false,
        size: 60,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          <WarningLink href={getProjectLink(row.original, true)} className="text-light">
            <Icon
              type="link"
            />
          </WarningLink>
        ),
      },
    ],
    []
  );

  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [fullData, setFullData] = useState([]);

  const isFavorite = (id) => {
    const favorites = localStorage.getItem("favorites") || "";
    return favorites.includes("[" + id + "]")
  }

  useEffect(() => {
    const temp = [...props.data];
    if (sorting.length == 0) {
      temp.sort((a, b) => {
        if (a.sortWeight == b.sortWeight)
          if (isFavorite(a.id) != isFavorite(b.id)) return isFavorite(a.id) ? -1 : 1;
        (a.sortWeight < b.sortWeight ? 1 : -1);
      });
      setData(temp);
      return;
    }

    temp.sort((a, b) => {
      if (a.sortWeight == b.sortWeight) {
        if (isFavorite(a.id) != isFavorite(b.id)) return isFavorite(a.id) ? -1 : 1;

        if (sorting[0].desc) {
          let c = a;
          a = b;
          b = c;
        }
        if (typeof a[sorting[0].id] == "string") return a[sorting[0].id].localeCompare(b[sorting[0].id]);
        else return parseFloat(a[sorting[0].id]) > parseFloat(b[sorting[0].id]) ? 1 : -1;
      } else {
        return a.sortWeight < b.sortWeight ? 1 : -1;
      }
    });

    setData(temp);
  }, [sorting]);

  const DetailPanel = (row) => {
    if (!row.original)
      return (
        <Box className="table-details"
          onClick={(event) => {

            event.stopPropagation();
          }}
          sx={{
            height: "290px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      );
    return (
      <Box className="table-details"
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Container className="m-3">
          {!(row.original.TVL.TVLUSD < 3 && getProjectAge(row.original.launch_date) > 3)
            ? <Row style={{ width: "100%" }}>
              <Col md={4} lg={4}>
                <p className="mb-2">{row.original.description ? row.original.description : "No description provided..."}</p>
                {row.original.social != null || row.original.audits != null ? (
                  <Row className="mb-2">
                    <Col lg={12} className="mb-3 d-flex justify-content-around text-justify">
                      {row.original.social.map((item, index) => {
                        return (
                          <Col key={index} className="text-center">
                            <WarningLink href={getSocialLink(item.name) + item.value}>
                              <Icon type={item.name} fontSize={30} stuff={1} />
                            </WarningLink>
                          </Col>
                        );
                      })}
                      {row.original.marks?.map((item, index) => {
                        return (
                          <Col key={index} className="text-center">
                            <img src={getSecurityIcon(item.name)} alt={item.name} width={30} height={30} />
                          </Col>
                        )
                      })}
                      {row.original.audits?.map((item, index) => {
                        return (
                          <Col key={index} className="text-center">
                            <a href={item.link}>
                              <img src={getSecurityIcon(item.name)} alt={item.name} width={30} height={30} />
                            </a>
                          </Col>
                        );
                      })}
                    </Col>
                  </Row>
                ) : null}
                <p className="text-center mb-0"></p>
                <p className="d-flex justify-content-around">
                  <Button href={config.thisURL + "/project/" + row.original.slug + "/"}>More</Button>
                  <Button href={getNetworkLink(row.original.network.slug) + row.original.contract_address} target="_blank">Contract </Button>
                  <WarningLink href={getProjectLink(row.original)}>
                    <Button nohref={true}>DAPP</Button>
                  </WarningLink>
                </p>
              </Col>
              {!isProjectUpcoming(row.original.launch_date)
                ?
                <><Col md={4} lg={4}>
                  <LinkContainer to={"/project/" + row.original.slug + "/"} style={{ width: "100%", display: "block" }}>
                    <Nav.Link className="table_desktop__chart_link">
                      <MyCustomChart
                        chartData={{
                          TVLUSD: { data: row.original?.TVLChart.data, key: "sumUSD", as: "TVL USD" },
                        }}
                        title="TVL history"
                      />
                    </Nav.Link>
                  </LinkContainer>
                </Col>
                  <Col md={4} lg={4}>
                    <LinkContainer to={"/project/" + row.original.slug + "/"} style={{ width: "100%", display: "block" }}>
                      <Nav.Link className="table_desktop__chart_link">
                        <MyCustomChart
                          shortToolTip={true}
                          chartData={{
                            deposits: {
                              data: row.original?.depositsChart.data,
                              key: "sumUSD",
                              as: "Deposits USD",
                            },
                            withdrawals: {
                              data: row.original?.withdrawalsChart.data,
                              key: "sumUSD",
                              as: "Withdrawals USD",
                            },
                          }}
                          title="Withdrawals/Deposits history"
                        />
                      </Nav.Link>
                    </LinkContainer>
                  </Col></>
                :
                <Col md={8} lg={8}><CountdownTimer to={row.original.launch_date} headline={false} /></Col>
              }
            </Row>
            : <h3>Project is dead and will be removed shortly</h3>
          }
        </Container>
      </Box>
    );
  };
  return (
    <Container className="main-table o-block p-0 mt-3">
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <MaterialReactTable
          className="desktop"
          columns={columns}
          manualSorting={true}
          state={{ sorting }}
          onSortingChange={setSorting}
          data={data}
          enablePagination={false}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableExpandAll={false}
          enableExpanding={true}
          enableBottomToolbar={false}
          renderEmptyRowsFallback={() => <Loader />}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              if (event.target.tagName == "svg") return;
              row.toggleExpanded();
              if (fullData[row.original.id]) {
                // don`t sure if we need this
                // setFullData((prev) => {
                //   const temp = { ...prev };
                //   delete temp[row.original.id];
                //   return temp;
                // });
              } else {
                setTimeout(() => {
                  fetch(config.serverEndpoint + "project/" + row.original.id + "/full")
                    .then((response) => response.json())
                    .then((data) => {
                      setFullData(prev => ({
                        ...prev,
                        [row.original.id]: data.payload
                      }));
                    })
                    .catch((error) => console.log(error));
                }, 500);
              }
            },
            className: "table-row-" + (row.original.sortWeight > 128 ? "top" : "normal") + (row.original.isDead ? " table-row-dead-project" : ""),
            sx: {
              cursor: "pointer",
            },
          })}
          renderDetailPanel={({ row }) => <DetailPanel original={fullData[row.original.id]} />}
        />
      </ThemeProvider>
    </Container>
  );
}
export default TableDesktop;
