import { Helmet } from "react-helmet-async";
import AdsBanner from "../../components/AdsBanner/index.jsx";
import Stats from "../../components/StatsComponent";
import Table from "../../components/TableComponent";

function HomePage() {

  return (
    <div className="homepage">
      <Helmet>
        <title>Orientier #1 - your DAPP world guide</title>
      </Helmet>
      <Stats />
      <AdsBanner name="main-top" />
      <Table />
    </div>
  );
}

export default HomePage;
