import { getSecurityIcon } from "../../utils/imageHelpers.js";

function SecurityMarks(props) {
  if (props.marks) {

    const result = props.marks.map((item) => {
      let tooltipText;
      switch (item.name) {
        case "reviewed":
          tooltipText = "Contract source code reviewed by Orientier team"
          break
        case "UNAUDITED":
          tooltipText = "This project is not audited by any trusted auditor company"
          break;
        default:
          tooltipText = item.description == null ? item.name : item.description;
      }
      console.log(item)
      return (
        <p>
          <img src={getSecurityIcon(item.name)} alt={item.name} width={25} /> {tooltipText}
        </p>
      )
    })
    return (
      <>
        <h4>Additional security information</h4>
        {result}
      </>
    );
  }
}


export default SecurityMarks;