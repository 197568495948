function WarningLink(props) {
  const showOverlay = () => {
    document.getElementById('leavepage').style.display = "flex";
    document.body.style.overflow = 'hidden';
    document.getElementById('leavepage__button').href = props.href;
  }

  const checkWarnings = () => {
    if (localStorage.getItem('disableWarnings')) {
      window.open(props.href, '_blank');
    } else {
      showOverlay();
    }
  }

  return (
    <a onClick={checkWarnings} className={props.className} style={{ textDecoration: "none", cursor: "pointer" }}>
      {props.children}
    </a>
  );
}

export default WarningLink;