import TableRow from "./TableRow";

export function ScamTable(props) {
  if (props.data) {
    const rows = [];
    for (const item of props.data) {
      rows.push(<TableRow data={item} />);
    }
    return (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Reason</th>
            <th>Date added</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }
}