import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import "./DYOR.scss";

function DYOR() {
  return (
    <div className="dyor-page">
      <Helmet>
        <title>DYOR on Orientier #1</title>
      </Helmet>
      <Container className="my-4">
        <h1>Do Your Own Research</h1>
      </Container>
      <Container className="o-block py-3">
        <Row>
          <Col lg={12}>
            <p>DYOR stands for Do Your Own Research and is a common phrase used by cryptocurrency enthusiasts. It aims to reduce the number of uninformed investors in cryptocurrency.
              However, the acronym is not a piece of advice exclusive to the cryptocurrency ecosystem. It is commonly used throughout the internet due to how fast and easily misinformation can spread.</p>
            <h3>Why DYOR?</h3>

            <p>The DEFI world isn't a safe place; it's more like the wild west.
              Do you want to find your place here? Do you want to strike gold?
              Your most reliable partner is yourself.
              Only In Q2 2023 alone, more than $204 million was lost in DEFI due to scams, hacks, and theft.
              The only person you can 100% rely on is yourself. <span className="secondary-text-gradient">DO YOUR OWN RESEARCH</span>.</p>

            <p>If you don't understand smart contract code, you can't trust it completely.
              Ensure it's a fork of a well-known project and make sure it's been audited.
              Read the audit report attentively; the "score" means nothing, as the devil is in the details.  <span className="secondary-text-gradient"> DO YOUR OWN RESEARCH</span>.</p>

            <p>Remember, even auditors can miss something, so perform calculations, avoid putting all your eggs in one basket, and learn the basics of risk management. <span className="secondary-text-gradient"> DO YOUR OWN RESEARCH</span>.</p>

            <p>Words are just words; actions speak louder. Anyone can claim their project is safe and reliable, but have they created similar projects before?
              Were those projects successful?
              Any team can provide audits, but have any of the audited projects experienced a rug pull?
              Do auditors take responsibility for their mistakes? Can you trust mere words? <span className="secondary-text-gradient">DO YOUR OWN RESEARCH</span>.
            </p>

            <p>Even Orientier #1 does not provide guarantees.
              Yes, we offer charts, deposit/withdrawal data, TVL changes, audit links, and more, but we can't make decisions for you. We are actively working on better metrics, audit services, and hope to eventually provide reliable scores for projects. Until that time comes, and as always... <span className="secondary-text-gradient">DO YOUR OWN RESEARCH</span>.</p>
          </Col >
        </Row >
      </Container >
    </div >
  );
}

export default DYOR;
