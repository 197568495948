import config from "../../config.js";
import { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";

import { getLogoURL } from "../../utils/imageHelpers.js";

function Mosaic() {
  const [activeProjects, setActiveProjects] = useState(null);
  const [projectsCount, setProjectsCount] = useState(null)
  useEffect(() => {
    fetch(config.serverEndpoint + "project/active-list")
      .then((response) => response.json())
      .then((data) => setActiveProjects(data.payload.concat(data.payload)))
      .catch((error) => console.log(error));
  }, [])
  if (activeProjects) {
    return (
      <div className="mosaic-container">
        {activeProjects.map((project, iterator) => {
          if (iterator < 24) {
            return <LinkContainer to={"/project/" + project.slug} key={project.id * Math.random()}>
              <img src={getLogoURL(project.id)} title={project.name} key={project.id * Math.random()} />
            </LinkContainer>
          }
        })}
      </div>
    );
  }

}
export default Mosaic;
