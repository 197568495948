import React, { useState, useEffect } from "react";
import Icon from "../ControlElements/Icon/index.jsx";

function FavoriteIcon(props) {
    const [isFavorite, setFavorite] = useState(false);

    function handleClick() {
        const favorites = localStorage.getItem("favorites") || "";
        const newFavorites = favorites.includes("[" + props.id + "]")
            ? favorites.replace("[" + props.id + "]", "")
            : favorites + "[" + props.id + "]";
        localStorage.setItem("favorites", newFavorites);
        setFavorite(!isFavorite);
    }

    useEffect(() => {
        const favorites = localStorage.getItem("favorites") || "";
        setFavorite(favorites.includes("[" + props.id + "]"));
    }, [props.id]);

    return (
        <Icon
            className={isFavorite ? "favorite-project" : ""}
            type={isFavorite ? "favorite" : "notFavorite"}
            onClick={(event) => {
                event.stopPropagation();
                handleClick();
            }}
        />
    );
}

export default FavoriteIcon;