import React, { useState, useEffect } from "react";

import TableMobile from "./TableMobile";
import TableDesktop from "./TableDesktop";
import { getProjectAge, isProjectFresh, isProjectUpcoming } from "../../utils/basicHelpers.js";
import config from "../../config";

import "./Table.scss";

const Table = (props) => {
  const [projects, setProjects] = useState([]);

  const mapData = (data) => {
    const mappedData = [];
    data.forEach((project) => {
      mappedData.push({
        id: project.id,
        name: project.name,
        chain: project.network.slug,
        chainName: project.network.name,
        token: project.token.slug,
        age: getProjectAge(project.launch_date),
        launch_date: project.launch_date,
        daily: project.daily,
        TVLUSD: parseInt(project.TVL.TVLUSD.toFixed(0)).toLocaleString().replace(",", " "),
        TVL: project.TVL.TVL,
        tiker: project.token.tiker,
        price: 1,
        tvl24: project.TVL.TVL24H !== null ? project.TVL.TVL24H.toFixed(2) : 0,
        tvl7d: project.TVL.TVL7D !== null ? project.TVL.TVL7D.toFixed(2) : 0,
        feesIn: project.fees.deposit,
        feesOut: project.fees.withdrawal,
        sortWeight: project.sort_weight,
        link: project.web_link,
        webLink: project.web_link,
        logoLink: project.logo_link,
        socialMedia: project.socialMedia,
        isFavorite: project.isFavorite,
        tvlChart: project.tvlChart,
        referralPrefix: project.fees.referral_prefix,
        isUpcoming: isProjectUpcoming(project.launch_date),
        isFresh: project.isFresh,
        slug: project.slug,
        isDead: (project.TVL.TVLUSD < 3 && getProjectAge(project.launch_date) > 3),
        securityMarks: project.marks == null
          ? project.audits.concat()
          : project.audits == null
            ? project.marks
            : project.marks.concat(project.audits),
      });
    });
    setProjects(mappedData);
  };

  useEffect(() => {
    fetch(config.serverEndpoint + "project/all")
      .then((response) => response.json())
      .then((data) => mapData(data.payload))
      .catch((error) => console.log(error));
  }, []);

  const isMobile = window.innerWidth <= 1200;
  if (isMobile) {
    return <TableMobile data={projects} />;
  } else {
    return <TableDesktop data={projects} />;
  }
};

export default Table;
