import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import config from "../../config.js";
import { useState, useEffect } from "react";

import Mosaic from "./Mosaic.js";
import "./Stats.scss"
import { coinFormat } from "../../utils/basicHelpers.js";

function Stats() {
  const [stats, getStats] = useState(null);
  useEffect(() => {
    fetch(config.serverEndpoint + "global-tx-stats")
      .then((response) => response.json())
      .then((data) => getStats(data.payload))
      .catch((error) => console.log(error));
  }, []);
  if (stats) {
    return (
      <>
        <Container className="text-light rounded opacity-75 p-3 stats-component o-block">
          <Row className="d-flex justify-content-around">
            <Col lg={4}>
              <Mosaic />
            </Col>
            <Col lg={8}>
              <Row className="text-start">
                <Col lg="6">
                  <div className="mx-auto fit-content">
                    <h2>Current stats</h2>
                    <h3><span className="first-number">{stats.activeProjects - stats.upcomingProjects}</span> projects running</h3>
                    {
                      stats.upcomingProjects > 0 && <h3><span className="first-number">{stats.upcomingProjects}</span> project{stats.upcomingProjects != 1 ? "s" : ""} coming</h3>
                    }
                    {stats.freshProjects > 0 &&
                      <h3><span className="first-number">{stats.freshProjects}</span> fresh project{stats.freshProjects != 1 ? "s" : ""} </h3>
                    }
                    <h3><span className="first-number">${coinFormat(stats.activeTVL, { short: true })}</span> total TVL </h3>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mx-auto fit-content">
                    <h2 >All time</h2>
                    <h3><span className="first-number">{stats.totalProjects}</span>  projects tracked </h3>
                    <h3><span className="first-number">{stats.totalDepositorsCount}</span>  users </h3>
                    <h3><span className="first-number">${coinFormat(stats.totalWithdrawn, { short: true })}</span>  withdrawn 	 </h3>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Stats;
