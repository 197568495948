const dev = false; // true|false|"full" (local server included)
const thisDev = "http://" + window.location.hostname + ":3000";
const thisProd = "https://" + window.location.hostname;

const devServer = "http://localhost:3001/";
const prodServer = "https://data.orientier.one/";

export default {
  serverEndpoint: dev == "full" ? devServer : prodServer,
  thisURL: dev
    ? dev == "full"
      ? thisDev
      : "https://orientier.one"
    : thisProd,
};