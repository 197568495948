export function getProjectAge(launch_date) {
  const launched = ((Date.now() - Date.parse(launch_date)) / 86400000);
  if (launched >= 1) return launched | 0;
  if (launched <= -1) return "in " + (-launched);
  if (launched < 0) return "today";
  return "started";
}

export function isProjectUpcoming(launch_date) {
  return (typeof getProjectAge(launch_date) === "string" && getProjectAge(launch_date) !== "started") ? true : false;
}

export function isProjectFresh(TVL7D, launch_date) {
  const launched = ((Date.now() - Date.parse(launch_date)) / 86400000);
  if (launched < 5 && TVL7D > 0) {
    return true;
  }
  return false;
}

export function normalizeDate(value, year = true) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Novr", "Dec"];
  const date = new Date(value.slice(0, 10));
  return year
    ? date.getFullYear() + " " + months[date.getMonth()] + " " + date.getDate()
    : months[date.getMonth()] + " " + date.getDate();
}

export function cutHash(hash) {
  return hash.slice(0, 7) + "..." + hash.slice(-5);
}

export function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function coinFormat(amount, options = {}) {
  switch (options.slug) {
    case "wbnb":
    case "bnb":
      amount = amount.toFixed(4);
      break;
    case "matic":
      amount = amount.toFixed();
      break;
    case "arb":
    case "cake":
      amount = amount.toFixed();
      break;
    case "pls":
      amount = amount.toFixed();
      break;
    default:
      amount = options.short ? amount.toFixed(0) : amount.toFixed(2);
  }
  return amount.split(".")[0].replace(/(\d{1,3})(?=(\d{3})+(?!\d))/g, "$& ")
    + (amount.split(".")[1] ? "." + amount.split(".")[1] : "");
}

export function getNetworkLink(network) {
  switch (network) {
    case "bnb":
    case 2:
      return "https://bscscan.com/address/";
    case "polygon":
    case 3:
      return "https://polygonscan.com/address/";
    case "fantom":
      return "https://ftmscan.com/address/";
    case "avax":
      return "https://cchain.explorer.avax.network/address/";
    case "heco":
      return "https://hecoinfo.com/address/";
    case "eth":
    case 1:
      return "https://etherscan.io/address/";
  }
}

export function getScanLink(network) {
  switch (network) {
    case "bnb":
    case 2:
      return "https://bscscan.com/";
    case "polygon":
    case 3:
      return "https://polygonscan.com/";
    case "eth":
    case 1:
      return "https://etherscan.io/";
    case "pulse":
    case 5:
      return "https://scan.pulsechain.com/";
    case "arb":
    case 4:
      return "https://arbiscan.io/";
  }
}
export function getSocialLink(social) {
  switch (social) {
    case "telegram":
    case "telegram-chat":
      return "https://t.me/";
    case "twitter":
      return "https://x.com/";
    case "facebook":
      return "https://facebook.com/";
    case "discord":
      return "https://discord.com/invite/";
    case "youtube":
      return "https://youtube.com/";
    case "instagram":
      return "https://instagram.com/";
    case "whatsapp":
      return "https://chat.whatsapp.com/";
  }
}

export function getProjectLink(project, crutch = false) {
  return crutch
    ? project.referralPrefix
      ? project.webLink + project.referralPrefix + localStorage.getItem("global-referral-address")
      : project.webLink
    : project.fees?.referral_prefix
      ? project.web_link + project.fees.referral_prefix + localStorage.getItem("global-referral-address")
      : project.web_link;
}

export async function postData(url = "", data = {}) {
  try {
    const response = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (e) {
    return {
      error: { message: "Connection issues... Try again later or contact us via telegram." }
    }
  }
}

