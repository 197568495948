import { useEffect, useReducer } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../../components/ControlElements/Button";
import { postData } from "../../utils/basicHelpers.js";
import config from "../../config.js";
import Loader from "../../components/Loader";
import "./SubmitDAPP.scss"

function SubmitDAPP() {
  const endPoint = config.serverEndpoint + "submit-project";
  const initialData = {
    name: null,
    network: null,
    token: null,
    launch_date: null,
    web_link: null,
    contract_address: null,
    owner_contact: null,
    deposit_fee: null,
    withdrawal_fee: null,
    referral_fee: null,
    daily: null,
    more: null,
    description: null,
    social: null,
    audits: null
  }
  const initialValidation = {
    validated: false,
    valid: false,
    success: false,
    response: false,
    requested: false
  }
  const reducer = (state, newState) => {
    return {
      ...state,
      ...newState
    };
  }
  const [data, setData] = useReducer(reducer, initialData);
  const [validation, setValidation] = useReducer(reducer, initialValidation);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity() === false) {
      setValidation({ validated: false });
      setValidation({ valid: false });
    } else {
      setValidation({ valid: true });
      async function sendData() {
        setValidation({ requested: true })
        setValidation({ response: await postData(endPoint, data) });
      }
      sendData();
    }
    setValidation({ validated: true });

  };
  useEffect(() => {
    if (validation.response.success) {
      setValidation({ error: false })
      setValidation({ success: true })
      setValidation({ requested: false });
    }
    if (validation.response.error) {
      setValidation({ error: true })
      setValidation({ requested: false });
    }
  }, [validation.response])

  return (
    <div className="submit-page">
      <Helmet>
        <title>Submit your project on Orientier #1</title>
      </Helmet>
      <Container className="my-4">
        <h1>Submit project</h1>
      </Container>
      <Container className="o-block px-5 py-3">
        {!validation.success &&
          <Form noValidate validated={validation.validated} onSubmit={handleSubmit}>
            <Row className="my-3">
              <Form.Group as={Col} lg="6">
                <Form.Label>Project Name*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name of your awesome dapp"
                  onChange={(e) => setData({ name: e.target.value })}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg="3">
                <Form.Label>Network*</Form.Label>
                <Form.Select required={true} size="md" onChange={(e) => setData({ network: e.target.value })} >
                  <option value="">Select Network</option>
                  <option value={1}>Ethereum</option>
                  <option value={2}>BNB Chain</option>
                  <option value={3}>Polygon</option>
                  <option value={4}>Pulse Network</option>
                  <option value={5}>Arbitrum</option>
                  <option value={0}>Other</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} lg="3">
                <Form.Label>Token*</Form.Label>
                <Form.Select required={true} size="md" onChange={(e) => setData({ token: e.target.value })}>
                  <option value="">Select Currency</option>
                  <option value={1}>USDC</option>
                  <option value={2}>USDT</option>
                  <option value={3}>BNB</option>
                  <option value={4}>MATIC</option>
                  <option value={5}>ARB</option>
                  <option value={6}>CAKE</option>
                  <option value={99}>BUSD</option>
                  <option value={100}>PLS</option>
                  <option value={101}>WBNB</option>
                  <option value={0}>Other</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg="4">
                <Form.Label>Contract address*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="0x..."
                  onChange={(e) => setData({ contract_address: e.target.value })}
                />
                <Form.Control.Feedback>Okay</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg="4">
                <Form.Label>Website address*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="https://.."
                  onChange={(e) => setData({ web_link: e.target.value })}
                />
                <Form.Control.Feedback>Nice</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg="4">
                <Form.Label>Owner personal contact* (telegram preferred)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="@tgname"
                  onChange={(e) => setData({ owner_contact: e.target.value })}
                />

                <Form.Control.Feedback>Nice to meet you</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg="4">
                <Form.Label>Launch date* </Form.Label>
                <Form.Control
                  required
                  type="datetime-local"
                  max="2035-12-12"
                  onChange={(e) => setData({ launch_date: e.target.value })}
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <Form.Label>Daily interest*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="some %"
                  onChange={(e) => setData({ daily: e.target.value })}
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <Form.Label>Deposit fee (if any)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="some %"
                  onChange={(e) => setData({ deposit_fee: e.target.value })}
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <Form.Label>Withdrawal fee (if any)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="some % "
                  onChange={(e) => setData({ withdrawal_fee: e.target.value })}
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <Form.Label>Referral bonus (if any)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="some %"
                  onChange={(e) => setData({ referral_fee: e.target.value })}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg="6">
                <Form.Label>Project description </Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Our awesome project is so awesome..."
                  onChange={(e) => setData({ description: e.target.value })} />
              </Form.Group>
              <Form.Group as={Col} lg="3">
                <Form.Label>Social links* (tg, discord, twitter) </Form.Label>
                <Form.Control required as="textarea" rows={3} placeholder="https://..."
                  onChange={(e) => setData({ social: e.target.value })} />
              </Form.Group>
              <Form.Group as={Col} lg="3">
                <Form.Label>Audits links (if any) </Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="https://..."
                  onChange={(e) => setData({ audits: e.target.value })} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg="12">
                <Form.Label>Anything else we need to know... </Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Notes, wishes, comments...  "
                  onChange={(e) => setData({ more: e.target.value })} />
              </Form.Group>
            </Row>
            <Row>
              {validation.response.error &&
                <p style={{ color: "red", textAlign: "center" }}>{validation.response.error.message}</p>
              }
              <Col lg={{ span: 2, offset: 5 }} className="text-center">

                {validation.requested
                  ? <Loader></Loader>
                  : <Button type="submit">Submit</Button>
                }
              </Col>
            </Row>
          </Form>
        }
        {validation.success &&
          <h3 className="mt-3 secondary-text-gradient ">Thanks! We will check your project and contact you shortly!</h3>
        }
      </Container>
    </div >
  );

}

export default SubmitDAPP;
