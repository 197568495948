import { Badge } from "react-bootstrap";
import config from "../../config.js";
function searchItems(args) {
  let data = args.data;
  let lines = [];
  if (data) {
    for (const item of data) {
      if (item.type == "project") {
        const link = config.thisURL + "/project/" + item.slug;
        lines.push(<li key={item.id}><a href={link} >{item.name}</a></li>);
      }
      if (item.type == "scam") {
        const link = config.thisURL + "/scam-alerts/";
        lines.push(<li key={item.id}><a href={link} >{item.name}</a> <Badge bg="danger">scam</Badge></li>);
      }
    }
    if (data.length == 0) {
      lines = (<li key="99999">No results found</li>)
    }
    return (
      <ul>
        {lines}
      </ul>
    )
  }
}
export default searchItems;