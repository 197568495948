
import { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import config from "../../config.js";
import SearchItems from "./SearchItems.js";

import "./SearchBox.scss";
function SearchBox() {
  const [query, setQuery] = useState(0);
  const [queryResult, setQueryResult] = useState(null);
  useEffect(() => {
    if (query.length > 3) {
      fetch(config.serverEndpoint + "search/" + query)
        .then((response) => response.json())
        .then((data) => setQueryResult(data.payload))
        .catch((error) => console.log(error));
    } else {
      setQueryResult(null)
    }
  }, [query])
  return (
    <Form className="search-box" noValidate>
      <Form.Group as={Col} lg="12">
        <Form.Control
          style={{ backgroundColor: "transparent" }}
          required
          type="text"
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="search-results">
          <SearchItems data={queryResult} />
        </div>
      </Form.Group>
    </Form>
  )
}
export default SearchBox;