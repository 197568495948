import { useEffect, useState } from "react";
import "./CountdownTimer.scss"
function CountdownTimer(props) {
  const [seconds, setSeconds] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [hours, setHours] = useState(null);
  const [days, setDays] = useState(null);
  useEffect(() => {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;
    const countDown = new Date(props.to).getTime();
    setInterval(function () {
      const now = new Date().getTime();
      const distance = countDown - now;
      setSeconds(Math.floor((distance % minute) / second))
      setMinutes(Math.floor((distance % hour) / minute))
      setHours(Math.floor((distance % day) / hour))
      setDays(Math.floor(distance / day))
    }, 0);
  }, [])
  return props.micro ?
    (
      <div id="countdown">
        {days + hours + minutes + seconds > 0 &&
          <>
            {days > 0 ? `${days} ${days > 1 ? 'days' : 'day'}` : ''} {hours}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
          </>
        }
      </div>
    )
    :
    (
      <div className="countdown-timer">
        {props.headline !== false &&
          <>
            {days + hours + minutes + seconds > 0
              ?
              <h1 id="headline">Launches in</h1>
              :
              <h1 id="headline">Project just launched</h1>
            }
          </>
        }
        <div id="countdown">
          {days + hours + minutes + seconds > 0 &&
            <ul>
              <li><span id="days">{days}</span>days</li>
              <li><span id="hours">{hours}</span>Hours</li>
              <li><span id="minutes">{minutes}</span>Minutes</li>
              <li><span id="seconds">{seconds}</span>Seconds</li>
            </ul>
          }
        </div>
      </div >
    )

}
export default CountdownTimer;