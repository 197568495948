import { getScanLink, cutHash } from "../../utils/basicHelpers.js";

function TableRow(props) {
  console.log(props)
  return (
    <tr>
      <td>{props.data.name} <a className="address-bottom" target={"_blank"} href={getScanLink(props.data.network) + "address/" + props.data.contract_address}>{cutHash(props.data.contract_address)}</a></td>
      <td dangerouslySetInnerHTML={{ __html: props.data.description }}></td>
      <td>{new Date(props.data.timestamp).toDateString().split(' ').slice(1).join(' ')}</td>
      {/* <td><WarningLink href={props.data.web_link}><Icon type="link" /></WarningLink></td> */}
    </tr>
  )
}
export default TableRow;