import React, { createContext, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import config from "./config";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import FAQ from "./pages/FAQ";
import DYOR from "./pages/DYOR";
import Scam from "./pages/Scam";
import SubmitDAPP from "./pages/SubmitDAPP";
import SubmitScam from "./pages/SubmitScam";
import Project from "./pages/Project";
import ReactGA from 'react-ga4';

import "./index.scss";
import NotFoundPage from "./pages/404";
import Changelog from "./pages/Changelog/index.jsx";

export default function App() {
  ReactGA.initialize('G-H5ZFBC1EKB');
  ReactGA.send({ hitType: "pageview", page: "/" });

  useEffect(() => {
    fetch(config.serverEndpoint + "get-global-referral")
      .then((response) => response.json())
      .then((data) => (localStorage.setItem(`global-referral-address`, data.payload.address)))
      .catch((error) => console.log(error));
  })
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="project/:identifier/:slug?" element={<Project />} />
            <Route path="FAQ" element={<FAQ />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="DYOR" element={<DYOR />} />
            <Route path="changelog" element={<Changelog />} />
            <Route path="scam" element={<Scam />} />
            <Route path="scam-alerts" element={<Scam />} />
            <Route path="submit-scam" element={<SubmitScam />} />
            <Route path="report-scam" element={<SubmitScam />} />
            <Route path="SubmitDAPP" element={<SubmitDAPP />} />
          </Route>
        </Routes>
      </BrowserRouter></HelmetProvider >
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
