export default {
  rows: [
    {
      title: "What is Orientier #1?",
      content: `Orientier #1 is a High-Yield DEFI projects aggregator (a.k.a ROI miners). It tracks and aggregates most valuable infromation like TVL changes, daily interest, fees, and other.
      Also we are gathering projects social information, audits, etc. `,
    },
    {
      title: "What is High-Yield investment project (ROI miner)?",
      content:
        `A High-Yield Investment Project, often referred to as an ROI Miner, is a type of investment opportunity that aims to maximize the return on investment (ROI) for participants. 
        In most cases, these projects resemble a Ponzi scheme in one form or another, whether simple or complex.
        They operate by using deposits from new investors to pay returns to existing ones.
        Newer participants essentially fund the returns of those who joined earlier, creating a chain of payments.
        This is why it's crucial to monitor the Total Value Locked (TVL) in the project.
        It helps you gauge whether there is still potential to enter the project or if you risk being among the last investors in the payment chain.`,
    },
    {
      title: "How is DEFI related to all of this?",
      content: `DEFI brought a revolution to the finance industry, as smart contracts provide transparency and trust.
       Before DeFi, high-yield investments were incredibly risky because operators or owners could "rug pull" at any minute, leaving investors with nothing.`,
    },
    {
      title: "What is rug pull? Do smart contracts protect me?",
      content: `The term "rug pull" is derived from the idiom "to pull the rug out from under someone," leaving the victim off-balance and scrambling. 
        It refers to a type of scam where an individual in whom people have entrusted their funds suddenly disappears with all of them.
        Does DeFi protect us? The answer is both yes and no. In theory, it does provide protection, but the real answer lies in the details.
        Just like any computer program (which a smart contract is), it can have vulnerabilities or backdoors.
        A well-designed and straightforward smart contract will offer protection, but how can you be certain of that?
        The best way to gain confidence is to learn Solidity and read and understand the contract's source code yourself, although this path is not accessible to most people.
        This is why we have teams that conduct audits of contracts and publish reports about them. Audits are a vital component of DEFI.`
    },
    {
      title: "So, if a project is audited, can I invest in it safely?",
      content: `Not necessarily, but it can certainly increase your trust in the project. By reading an audit report, you can gain a better understanding of it.
       Additionally, there are instances where even auditors may overlook vulnerabilities or backdoors.
       This is why the DYOR principle is so crucial.`
    },
    {
      title: "What is DYOR?",
      content: <p>DYOR stands for Do Your Own Research. Rean our <a href="https://orientier.one/DYOR">article</a> about it. As this is key principle of making desicions in DEFI world.</p>
    },
    {
      title: "Is it all worth it? So much variables, so much risks.",
      content: `High risks means high (huge?) returns. Does it worth it? You decide.`
    },
    {
      title: "So, what is the primary purpose of Orientier #1? Can it guide me on where to invest?",
      content: `Orientier #1's main goal is to consolidate project information, making your research easier.
       When we list a project, we aim to provide as much information about it as possible.
       In the future, we plan to designate certain projects as trusted based on our own research.
       Additionally, in Q4 2023, we intend to launch a small wiki with more information and explanations to help you delve deeper into this world.
       Moreover, in Q1 2024, we will be introducing deposit insurance.`},
    {
      title: "Is there anything else   I need to know?",
      content: <p>If you're seeking more information, join us in <a href="https://t.me/orientier_one">telegram</a> or <a href="https://twitter.com/orientier">twitter</a> and staying tuned!
        We will keep you informed as we release new features on our website and update its database.</p>
    },
  ],
};