import { useState, useEffect } from "react";
import config from "../../config.js";

function AdsBanner(props) {
  const [banner, getBanner] = useState(null);
  useEffect(() => {
    fetch(config.serverEndpoint + "ads/" + props.name)
      .then((response) => response.json())
      .then((data) => getBanner(data.payload))
      .catch((error) => console.log(error));
  }, []);

  if (banner?.active) {
    return (
      <a className={props.name + "-banner"} href={banner.link} target="_blank"><img src={banner.image} /></a>
    )
  }
}

export default AdsBanner;