import { FaChartArea, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { GoLinkExternal, GoShieldCheck, GoStar, GoStarFill } from "react-icons/go";
import { BsDiscord, BsTwitter, BsInstagram, BsFacebook, BsYoutube } from "react-icons/bs";
import { TbCodeCircle2 } from "react-icons/tb";

import "./Icon.scss"

function Icon(props) {
  const iconClass = "orientier-icon"
  switch (props.type) {
    case "telegram":
      return <FaTelegram title="Official Telegram" className={iconClass} {...props} />;
    case "telegram-chat":
      return <FaTelegram title="Telegram Chat" className={iconClass} {...props} />;
    case "chart":
      return <FaChartArea className={iconClass} {...props} />;
    case "link":
      return <GoLinkExternal className={iconClass} {...props} />;
    case "audit":
      return <GoShieldCheck className={iconClass} {...props} />;
    case "contract":
      return <TbCodeCircle2 className={iconClass} {...props} />;
    case "favorite":
      return <GoStarFill className={iconClass} {...props} />;
    case "notFavorite":
      return <GoStar className={iconClass} {...props} />;
    case "discord":
      return <BsDiscord className={iconClass} {...props} />;
    case "instagram":
      return <BsInstagram className={iconClass} {...props} />;
    case "facebook":
      return <BsFacebook className={iconClass} {...props} />;
    case "youtube":
      return <BsYoutube className={iconClass} {...props} />;
    case "twitter":
      return <BsTwitter className={iconClass} {...props} />;
    case "whatsapp":
      return <FaWhatsapp className={iconClass} {...props} />;
  }
}
export default Icon;
