import TableRow from "./TableRow";
export function ChangelogTable(props) {
  if (props.data) {
    const rows = [];
    for (const item of props.data) {
      rows.push(<TableRow data={item} />);
    }
    return (
      <table>
        <thead>
          <tr>
            <th>Changes</th>
            <th>Link</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }
}