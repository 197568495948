import { useEffect, useReducer } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../../components/ControlElements/Button";
import { postData } from "../../utils/basicHelpers.js";
import config from "../../config.js";
import Loader from "../../components/Loader";

function SubmitScam() {
  const endPoint = config.serverEndpoint + "submit-scam";
  const initialData = {
    contact: null,
    description: null
  }
  const initialValidation = {
    validated: false,
    valid: false,
    success: false,
    response: false,
    requested: false
  }
  const reducer = (state, newState) => {
    return {
      ...state,
      ...newState
    };
  }
  const [data, setData] = useReducer(reducer, initialData);
  const [validation, setValidation] = useReducer(reducer, initialValidation);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity() === false) {
      setValidation({ validated: false });
      setValidation({ valid: false });
    } else {
      setValidation({ valid: true });
      async function sendData() {
        setValidation({ requested: true })
        setValidation({ response: await postData(endPoint, data) });
      }
      sendData();
    }
    setValidation({ validated: true });
  };
  useEffect(() => {
    if (validation.response.success) {
      setValidation({ error: false })
      setValidation({ success: true })
      setValidation({ requested: false });
    }
    if (validation.response.error) {
      setValidation({ error: true })
      setValidation({ requested: false });
    }
  }, [validation.response])

  return (
    <div className="submit-page">
      <Helmet>
        <title>Submit your project on Orientier #1</title>
      </Helmet>
      <Container className="my-4">
        <h1>Report scam</h1>
      </Container>
      <Container className="o-block px-5 py-3">
        {!validation.success &&
          <Form noValidate validated={validation.validated} onSubmit={handleSubmit}>
            <Row className="my-3">
              <Form.Group as={Col} lg="6">
                <Form.Label>Project Name*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name of the scam project"
                  onChange={(e) => setData({ name: e.target.value })}
                />
                <Form.Control.Feedback>Okay</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg="6">
                <Form.Label>How we can contact you for more details* (telegram preferable)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Your contact"
                  onChange={(e) => setData({ contact: e.target.value })}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg="12">
                <Form.Label>Reason* </Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Why do you think this is scam? Add as much proofs as you can.  "
                  onChange={(e) => setData({ description: e.target.value })} />
              </Form.Group>
            </Row>
            <Row>
              {validation.response.error &&
                <p style={{ color: "red", textAlign: "center" }}>{validation.response.error.message}</p>
              }
              <Col lg={{ span: 2, offset: 5 }} className="text-center">

                {validation.requested
                  ? <Loader></Loader>
                  : <Button type="submit">Submit</Button>
                }
              </Col>
            </Row>
          </Form>
        }
        {validation.success &&
          <h3 className="mt-3 secondary-text-gradient ">Thanks! We will check your submission and will add it into scam list if we'll make same conclusion as you. We also may contact you to get more info.</h3>
        }
      </Container>
    </div >
  );

}

export default SubmitScam;
