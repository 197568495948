export function getTvlChangeColor(change) {
  switch (true) {
    case change < -15:
      return "tvl-change  tvl-drop-5";
    case change < -5:
      return "tvl-change tvl-drop-3";
    case change < 0:
      return "tvl-change tvl-drop-1";
    case change > 15:
      return "tvl-change tvl-increase-5";
    case change > 5:
      return "tvl-change tvl-increase-3";
    case change >= 0:
      return "tvl-change tvl-increase-1";
  }
}

export function getFeeColor(fee) {
  switch (true) {
    case fee > 20:
      return "fee-color fee-high-5";
    case fee > 14:
      return "fee-color fee-high-3";
    case fee > 10:
      return "fee-color fee-high-1";
    case fee > 8:
      return "fee-color fee-low-5";
    case fee > 5:
      return "fee-color fee-low-3";
    case fee >= 0:
      return "fee-color fee-low-1";
  }
}
