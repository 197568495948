import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Icon from "../../ControlElements/Icon/index.jsx";
import { getImageURL } from "../../../utils/imageHelpers.js";
import { LinkContainer } from "react-router-bootstrap";
import SearchBox from "../../SearchBox/index.jsx";

function NavBar() {
  return (
    <Navbar expand="md" data-bs-theme="dark">
      <Container>
        <LinkContainer to="/" >
          <Navbar.Brand>
            <img src={getImageURL(`orienteer-4.png`)} width={200}></img>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-3">
            <NavLink className="nav-link" to="/FAQ">FAQ</NavLink>
            <NavLink className="nav-link" to="/DYOR">DYOR</NavLink>
            <NavLink className="nav-link" to="/SubmitDAPP">Submit dApp</NavLink>
            <NavDropdown title="Get crypto" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://accounts.binance.com/register?ref=76531284" target="_blank">
                Binance
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.okx.com/join/15507263" target="_blank">
                OKX
              </NavDropdown.Item>
              <NavDropdown.Item href="https://whitebit.com/referral/d68273ee-a353-4bc5-af47-7e22dd6d9f94" target="_blank">
                WhiteBit
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="https://paybis.affise.com/v2/sign/up?ref=17963" target="_blank">Paybifs</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="https://app.uniswap.org/" target="_blank">Uniswap</NavDropdown.Item>
              <NavDropdown.Item href="https://pancakeswap.finance/" target="_blank">Pancakeswap</NavDropdown.Item>
              <NavDropdown.Item href="https://app.1inch.io/" target="_blank">1Inch</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <SearchBox />
          <Nav className="ms-auto mb-2 mb-md-0 nav-social">
            <NavLink className="nav-link" to="/scam">scam alert</NavLink>
            <NavLink className="pfff nav-link" to="https://t.me/orientier_one" target="_blank">
              <Icon type="telegram" stuff={1} />
            </NavLink>
            <NavLink className="pfff nav-link" to="https://twitter.com/orientier" target="_blank">
              <Icon type="twitter" stuff={1} />
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  );
}

export default NavBar;
