import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { getNetworkLink, isProjectUpcoming, getProjectAge, getSocialLink } from "../../utils/basicHelpers.js";
import { getTvlChangeColor, getFeeColor } from "../../utils/colorHelpers";
import { showNetworkToken, showAge, showNameColumn, showTVLChanges, showTVL7D, showTVL24H } from "./columns.js";
import Button from "../ControlElements/Button";
import Icon from "../ControlElements/Icon/index.jsx";
import Loader from "../Loader";

import config from "../../config";
import { getSecurityIcon } from "../../utils/imageHelpers.js";


function TableMobile(props) {
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [fullData, setFullData] = useState({});

  const isSize = (size) => window.innerWidth <= size;

  useEffect(() => {
    setData(props.data);
  }, [props]);

  function getColumns() {
    let columns = [];


    if (isSize(440)) {
      columns.push({
        accessorKey: "name",
        header: "Name",
        Header: () => (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", height: "100%" }} className="very-custom">
            <Box>
              Name
            </Box>
            <Box>
              &nbsp; <br />&nbsp;
            </Box>
          </Box>
        ),
        size: 110,
        enableResizing: false,
        Cell: ({ row }) => (
          showNameColumn(row)
        ),
      });
      columns.push({
        accessorKey: "chain",
        header: "Token  Age TVL 7d/24h",
        enableSorting: false,
        Header: () => (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} className="very-custom">
            <Box>
              Token
            </Box>
            <Box>
              Age
            </Box>
            <Box sx={{ textAlign: "right", width: "35%" }}>
              TVL 7d <br />
              TVL 24h
            </Box>
          </Box>
        ),
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ textAlign: "center", width: "25%" }} className="mobile-token-network">
              {showNetworkToken(row)}
            </Box>
            <Box sx={{ textAlign: "center", width: "40%" }}>
              {showAge(row)}
            </Box>
            <Box sx={{ textAlign: "right", width: "35%" }}>
              {showTVLChanges(row)}
            </Box>
          </Box>
        )
      });
      return columns;
    }

    columns.push({
      accessorKey: "name",
      header: "Name",
      size: 140,
      enableResizing: false,
      Cell: ({ row }) => (
        showNameColumn(row)
      ),
    });

    columns.push({
      accessorKey: "chain",
      header: "Token",
      align: "center",
      size: 10,
      enableResizing: false,
      enableSorting: !isSize(480),
      Cell: ({ row }) => (
        <Box className="mobile-token-network">
          {showNetworkToken(row)}
        </Box>
      ),
    });
    //if (!isSize(770)) {
    columns.push({
      accessorKey: "age",
      header: "Age",
      size: 10,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        showAge(row)
      )
    });
    //}

    if (!isSize(990)) {
      columns.push({ accessorKey: "daily", size: 10, Header: "Daily", });
    }

    if (isSize(480)) {
      columns.push({
        accessorKey: "tvl7",
        header: "TVL 7d/24h",
        size: 15,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          showTVLChanges(row)
        ),
      });
    } else if (isSize(990)) {
      columns.push({
        accessorKey: "tvl7",
        header: "TVL 7d/24h",
        size: 20,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          showTVLChanges(row)
        ),
      });

    } else {
      columns.push({
        accessorKey: "tvl24",
        header: "TVL 24h",
        size: 20,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          showTVL24H(row)
        ),
      },
        {
          accessorKey: "tvl7",
          header: "TVL 7d",
          size: 20,
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          Cell: ({ row }) => (
            showTVL7D(row)
          ),
        },);
    }

    if (!isSize(770)) {
      columns.push({
        accessorKey: "fees",
        header: "Fees",
        size: 10,
        Cell: ({ row }) => (
          <Box>
            <span className={getFeeColor(row.original.feesIn)}>{row.original.feesIn}%</span>
            &nbsp;/&nbsp;
            <span className={getFeeColor(row.original.feesOut)}>{row.original.feesOut}%</span>
          </Box>
        ),
      });
    }


    return columns;
  }

  useEffect(() => {
    const temp = [...props.data];
    if (sorting.length == 0) {
      temp.sort((a, b) => (a.sortWeight < b.sortWeight ? 1 : -1));
      setData(temp);
      return;
    }

    temp.sort((a, b) => {
      if (a.sortWeight == b.sortWeight) {
        if (sorting[0].desc) {
          let c = a;
          a = b;
          b = c;
        }
        if (typeof a[sorting[0].id] == "string") return a[sorting[0].id].localeCompare(b[sorting[0].id]);
        else return parseFloat(a[sorting[0].id]) > parseFloat(b[sorting[0].id]) ? 1 : -1;
      } else {
        return a.sortWeight < b.sortWeight ? 1 : -1;
      }
    });

    setData(temp);
  }, [sorting]);

  const DetailPanel = (row) => {
    if (!row.original)
      return (
        <Box
          onClick={(event) => { event.stopPropagation(); }}
          sx={{
            height: "270px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Loader />
        </Box >
      );

    if (isProjectUpcoming(row.original.launch_date)) {
      return (
        <Box
          className="table-details"
          onClick={(event) => { event.stopPropagation(); }}
          sx={{
            display: "flex",
            width: "100%",
          }}>
          <Container>
            <Row><CountdownTimer to={row.original.launch_date} headline={false} /></Row>
            <Row className="mb-2 text-center">{row.original.description ? row.original.description : "No description provided..."}</Row>
            {row.original.social != null || row.original.audits != null ? (
              <Row className="mb-4" style={{ display: "flex", justifyContent: "space-between" }}>
                {row.original.social.map((item, index) => {
                  return (
                    <Col key={index} className="text-center">
                      <a href={getSocialLink(item.name) + item.value}>
                        <Icon type={item.name} fontSize={30} color="#eeeeee" />
                      </a>
                    </Col>
                  );
                })}
                {row.original.audits?.map((item, index) => {
                  return (
                    <Col key={index} className="text-center">
                      <a href={item.link}>
                        <Icon type="audit" fontSize={30} color="#eeeeee" />
                      </a>
                    </Col>
                  );
                })}
              </Row>
            ) : null}

            <Row><p className="text-center mb-0"><Button className="mob_buttton" href={"/project/" + row.original.slug + "/"}>More information</Button></p></Row>
            <Row><p className="text-center mb-0"><Button className="mob_buttton" href={getNetworkLink(row.original.network.slug) + row.original.contract_address} target="_blank">Contract </Button></p></Row>
            <Row><p className="text-center mb-0"><Button className="mob_buttton" href={row.original.web_link} warning={true} >DAPP</Button></p></Row>
          </Container>
        </Box>
      );
    }

    if ((row.original.TVL.TVLUSD < 3 && getProjectAge(row.original.launch_date) > 3))
      return (
        <h3>Project is dead and will be removed shortly</h3>
      );

    return (
      <Box
        className="table-details"
        onClick={(event) => { event.stopPropagation(); }}
        sx={{
          display: "flex",
          width: "100%",
        }}>
        <Container>
          {isSize(990) ? <>
            <Row className="mb-3">
              <Col>{isProjectUpcoming(row.original.launch_date) ?
                <CountdownTimer to={row.original.launch_date} headline={false} micro={true} />
                :
                <Box className="text-center">Age:<br />{getProjectAge(row.original.launch_date) == "started"
                  ? "started today"
                  : <>{getProjectAge(row.original.launch_date)} days</>
                } </Box>}
              </Col>
              <Col className="text-center">
                Daily:<br />{row.original.daily}
              </Col>
              <Col className="text-center">
                Fees:<br /><span className={getFeeColor(row.original.fees.deposit)}>{row.original.fees.deposit}%</span> / <span className={getFeeColor(row.original.fees.withdrawal)}>{row.original.fees.withdrawal}%</span>
              </Col>
            </Row><Row className="mb-3">
              <Col className="text-center">
                TVL 24h: <span className={getTvlChangeColor(row.original.TVL.TVL24H === null ? 0 : row.original.TVL.TVL24H)}>{(row.original.TVL.TVL24H === null ? 0 : row.original.TVL.TVL24H).toFixed(2)}%</span>
              </Col>
              <Col className="text-center">
                TVL: {row.original.TVL.TVL + " " + row.original.token.tiker}
              </Col>
            </Row>
          </> : null}

          <Row className="mb-2 text-center">{row.original.description ? row.original.description : "No description provided..."}</Row>

          {row.original.social != null || row.original.audits != null ? (
            <Row className="mb-4" style={{ display: "flex", justifyContent: "space-between" }}>
              {row.original.social.map((item, index) => {
                return (
                  <Col key={index} className="text-center">
                    <a href={getSocialLink(item.name) + item.value}>
                      <Icon type={item.name} fontSize={30} color="#eeeeee" />
                    </a>
                  </Col>
                );
              })}
              {row.original.marks?.map((item, index) => {
                return (
                  <Col key={index} className="text-center">
                    <img src={getSecurityIcon(item.name)} alt={item.name} width={30} height={30} />
                  </Col>
                )
              })}
              {row.original.audits?.map((item, index) => {
                return (
                  <Col key={index} className="text-center">
                    <a href={item.link}>
                      <img src={getSecurityIcon(item.name)} alt={item.name} width={30} height={30} />
                    </a>
                  </Col>
                );
              })}
            </Row>
          ) : null}

          <Row><p className="text-center mb-0"><Button className="mob_buttton" href={"/project/" + row.original.slug + "/"}>More information</Button></p></Row>
          <Row><p className="text-center mb-0"><Button className="mob_buttton" href={getNetworkLink(row.original.network.slug) + row.original.contract_address} target="_blank">Contract </Button></p></Row>
          <Row><p className="text-center mb-0"><Button className="mob_buttton" href={row.original.web_link} warning={true} >DAPP</Button></p></Row>
        </Container>
      </Box>
    );
  };

  return (
    <Container className="main-table o-block mobile mt-3">
      <ThemeProvider
        theme={createTheme({
          palette: {
            mode: "dark",
          },
        })}
      >
        <MaterialReactTable
          getRowId={(row) => row.id}
          columns={getColumns()}
          manualSorting={true}
          state={{ sorting }}
          onSortingChange={setSorting}
          data={data}
          enablePagination={false}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableBottomToolbar={false}
          renderEmptyRowsFallback={() => <Loader />}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              row.toggleExpanded();
              setTimeout(() => {
                if (fullData[row.original.id]) return;
                fetch(config.serverEndpoint + "project/" + row.original.id + "/full")
                  .then((response) => response.json())
                  .then((data) => {
                    setFullData((prev) => {
                      return { ...prev, [row.original.id]: data.payload };
                    });
                  })
                  .catch((error) => console.log(error));
              }, 200);
            },
            className: "table-row-" + (row.original.sortWeight > 128 ? "top" : "normal") + (row.original.isDead ? " table-row-dead-project" : ""),
            sx: {
              cursor: "pointer",
            },
          })}
          renderDetailPanel={({ row }) => <DetailPanel original={fullData[row.original.id]} />}
        />
      </ThemeProvider>
    </Container>
  );
}
export default TableMobile;
