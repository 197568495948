import { Container, Row, Col, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Icon from '../../ControlElements/Icon';

import "./Footer.scss";

function Footer() {
  return (
    <footer>
      <Container>
        <div className='footer-nav'>
          <div >
            <h4>Social</h4>
            <NavLink className="nav-link" to="https://t.me/orientier_one"><Icon type="telegram" stuff={1} /> Telegram</NavLink>
            <NavLink className="nav-link" to="https://t.me/orientier_one_chat"><Icon type="telegram" stuff={1} /> Telegram chat</NavLink>
            <NavLink className="nav-link" to="https://twitter.com/orientier"><Icon type="twitter" stuff={1} /> Twitter </NavLink>
          </div>
          <div >
            <h4>More</h4>
            <NavLink className="nav-link" to="/FAQ">FAQ</NavLink>
            <NavLink className="nav-link" to="/DYOR">DYOR</NavLink>
            <NavLink className="nav-link" to="/SubmitDAPP">Submit dApp</NavLink>
          </div>
          <div >
            <h4>CEXs</h4>
            <NavLink className="nav-link" to="https://accounts.binance.com/register?ref=76531284" target='blank'>
              Binance
            </NavLink>
            <NavLink className="nav-link" to="https://www.okx.com/join/15507263" target='blank'>
              OKX
            </NavLink>
            <NavLink className="nav-link" to="https://whitebit.com/referral/d68273ee-a353-4bc5-af47-7e22dd6d9f94" target='blank'>
              WhiteBit
            </NavLink>
          </div>
          <div >
            <h4>DEXs</h4>
            <NavLink className="nav-link" to="https://app.uniswap.org/" target='blank'>Uniswap</NavLink>
            <NavLink className="nav-link" to="https://pancakeswap.finance/" target='blank'>Pancakeswap</NavLink>
            <NavLink className="nav-link" to="https://app.1inch.io/" target='blank'>1Inch</NavLink>
          </div>
        </div>
        <Row>
          <Col lg={12}>
            <p className="mx-auto mt-4 mb-2 primary-text-gradient" style={{ width: "fit-content" }}>&copy; 2023 orientier.one</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;