import Button from "../../components/ControlElements/Button/index.jsx";
import "./Leave.scss";

function LeavePage(props) {

  const hideOverlay = () => {
    document.getElementById('leavepage').style.display = "none";
    document.body.style.overflow = 'auto';
  }

  const disableWarnings = () => {
    localStorage.setItem('disableWarnings', true);
    document.getElementById('leavepage__button').click();
  }

  return (
    <div className="overlay" style={{ display: "none" }} id="leavepage" >
      <div className="overlay__background rounded o-block">
        <h1>Warning!</h1>
        <p style={{ color: "red" }}>You are leaving our beautiful page 😒 Be careful 😘</p>
        <p><Button onClick={hideOverlay}>Go back 🔙</Button> <Button id="leavepage__button" href="#" target="_blank" onClick={hideOverlay}>Leave this page</Button></p>
        <p><a href="#" onClick={disableWarnings} style={{ color: "white" }}>Don`t show this window again</a></p>
      </div>
    </div>
  );
}

export default LeavePage; 