import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Faq from "react-faq-component";
import { Container, Row, Col } from "react-bootstrap";
import data from "./text.js";
import config from "./config.js";

import "./FAQ.scss"

function FAQ() {
  return (
    <div className="faq-page">
      <Helmet>
        <title>FAQ on Orientier #1</title>
      </Helmet>
      <Container className="my-4">
        <h1>Frequently asked questions</h1>
      </Container>
      <Container className="o-block">
        <Row>
          <Col lg={12}>
            <Faq
              data={data}
              config={config}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FAQ;
