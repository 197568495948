import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import config from "../../config.js";
import { ChangelogTable } from "./CnangelogTable.js";
import Loader from "../../components/Loader/index.jsx";

import "./Changelog.scss";

function Changelog() {
  const [list, setList] = useState(null);
  useEffect(() => {
    fetch(config.serverEndpoint + "changelog")
      .then((response) => response.json())
      .then((data) => setList(data.payload))
      .catch((error) => console.log(error));
  }, [])
  return (
    <div className="changelog">
      <Container className="my-4">
        <h1>Orientier #1 changelog</h1>
      </Container>
      <Container className="o-block px-5 py-3">
        <Row>
          <Col style={{ overflow: "auto" }}>
            {list
              ? <ChangelogTable data={list} />
              : <Loader />
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Changelog;