import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Box } from "@mui/material";
import CountdownTimer from "../CountdownTimer";
import { getLogoURL, getNetworkImageURL, getSecurityIcon, getTokenImageURL } from "../../utils/imageHelpers";
import { getTvlChangeColor } from "../../utils/colorHelpers";
export function showNameColumn(row) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isSize(420) ? null : <Box>
        <img
          alt="logo"
          className="project-logo"
          height={30}
          width={30}
          src={getLogoURL(row.original.id)}
          loading="lazy"
          style={{ borderRadius: "50%" }}
        /> </Box>}
      <Box>
        {row.original.name} {row.original.isDead && <Badge bg="danger">Dead</Badge>}
        <br />
        {showTVL(row)}
      </Box>
    </Box >)
}
export function showAge(row) {
  return row.original.isUpcoming
    ? <CountdownTimer to={row.original.launch_date} headline={false} micro={true} />
    : <Box>{row.original.age == "started" ? "started today" : <>{row.original.age} days</>} </Box>
}
export function showTVL(row) {
  return row.original.isUpcoming
    ? <Badge bg="secondary">coming</Badge>
    : <span className="text-secondary" style={{ fontSize: "small" }}>TVL: {row.original.TVLUSD} $</span>
}
export function showNetworkToken(row) {
  return (<><img
    alt={row.original.chain}
    height={isSize(440) ? 18 : 20}
    src={getNetworkImageURL(row.original.chain)}
    loading="lazy"
  />
    <img alt={row.original.token}
      height={isSize(440) ? 18 : 20}
      src={getTokenImageURL(row.original.token)}
      loading="lazy"
    />
  </>)
};
export function showTVLChanges(row) {
  return (<Box>
    {row.original.isUpcoming ?
      <Badge bg="secondary">coming</Badge> :
      <div><span className={getTvlChangeColor(row.original.tvl7d)}>{row.original.tvl7d}%</span><br />
        <span className={getTvlChangeColor(row.original.tvl24)}>{row.original.tvl24}%</span></div>}
  </Box>)
}

export function showTVL7D(row) {
  return (<Box>
    {row.original.isUpcoming
      ? <Badge bg="secondary">coming</Badge>
      : <div><span className={getTvlChangeColor(row.original.tvl7d)}>{row.original.tvl7d}%</span></div>}
  </Box>)
}
export function showTVL24H(row) {
  return (<Box>
    {row.original.isUpcoming
      ? <Badge bg="secondary">coming</Badge>
      : <div><span className={getTvlChangeColor(row.original.tvl24)}>{row.original.tvl24}%</span></div>
    }</Box>)
}

const isSize = (size) => window.innerWidth <= size;