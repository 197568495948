import { Table, Badge } from "react-bootstrap";
import { getProjectAge, coinFormat, isProjectUpcoming } from "../../utils/basicHelpers.js";
import { getFeeColor, getTvlChangeColor } from "../../utils/colorHelpers.js";
import { getNetworkImageURL, getTokenImageURL } from "../../utils/imageHelpers.js";

function StatsTable(props) {
  const project = props.project;
  if (project.TVL.TVL24H === null) project.TVL.TVL24H = 0;
  if (project.TVL.TVL7D === null) project.TVL.TVL7D = 0;
  if (project != null) {
    const feeColor = getFeeColor(parseFloat(project.fees.deposit) + parseFloat(project.fees.withdrawal));
    return (
      <Table hover data-bs-theme="dark" size="sm" className="mt-2 stats-table">
        <tbody>
          <tr>
            <td>Network</td>
            <td>
              <img className="network-icon" src={getNetworkImageURL(project.network.slug)} />
            </td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>
              <img className="token-icon" src={getTokenImageURL(project.token.slug)} />
            </td>
          </tr>
          <tr>
            <td>Age</td>
            <td>
              {project.isUpcoming
                ? <Badge bg="primary">coming</Badge>
                : getProjectAge(project.launch_date) == "started"
                  ? "started today"
                  : <>{getProjectAge(project.launch_date)} days</>
              }</td>
          </tr>
          <tr>
            <td>TVL</td>
            <td>

              {project.isUpcoming
                ? <Badge bg="primary">coming</Badge>
                : coinFormat(project.TVL.TVL, { short: true }) + " " + project.token.tiker
              }
              {ifOneToOne(project) && !project.isUpcoming && ` / \$${coinFormat(project.TVL.TVLUSD, { short: true })}`}
            </td>
          </tr>
          <tr>
            <td>TVL change 1d / 7d</td>
            <td>

              {project.isUpcoming
                ? <Badge bg="primary">coming</Badge>
                : <div><span className={getTvlChangeColor(project.TVL.TVL24H.toFixed(2))}>{project.TVL.TVL24H > 0
                  ? "+" + project.TVL.TVL24H.toFixed(2)
                  : project.TVL.TVL24H.toFixed(2)
                }%</span>{" "}
                  / <span className={getTvlChangeColor(project.TVL.TVL7D.toFixed(2))}>{project.TVL.TVL7D > 0
                    ? "+" + project.TVL.TVL7D.toFixed(2)
                    : project.TVL.TVL7D.toFixed(2)
                  }%</span></div>
              }
            </td>
          </tr>
          <tr>
            <td>Daily interest</td>
            <td>{project.daily}</td>
          </tr>
          <tr>
            <td>Deposit fee</td>
            <td>
              <span className={feeColor}>{project.fees.deposit}%</span>
            </td>
          </tr>
          <tr>
            <td>Withdrawal fee</td>
            <td>
              <span className={feeColor}>{project.fees.withdrawal}%</span>
            </td>
          </tr>
          {project.fees.referral &&
            <tr>
              <td>Referral bonus</td>
              <td>
                <span>{project.fees.referral}%</span>
              </td>
            </tr>
          }
        </tbody>
      </Table>
    );
  } else {
    return;
  }
}

function ifOneToOne(project) {
  return project.token.slug != "usdt" && project.token.slug != "usdc";
}

export default StatsTable;
