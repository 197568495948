import React, { useEffect, useState } from "react";
import { Line, XAxis, YAxis, Tooltip, Legend, ComposedChart, ResponsiveContainer } from "recharts";
import { Box } from "@mui/system";
import Loader from "../Loader";
import "./index.scss";

/**
 *
 * @param {{chartData: {data: Object, key: string, as?: string}[], width?: number, height?: number, title?: string, shortToolTip?: true | false}} props
 */
export default function chart(props) {
  const colors = ["#40c9ff", "#e81cff", "#ff0000", "#ffbf00", "#00ff00", "#00ffff", "#ff00ff", "#ff8000", "#0080ff"];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <b>{formatLabel(label)}</b>
          <br />
          {payload.map((element) => {
            return (
              <span key={element.name}>
                {`${element.name} : ${formatYAxis(element.value)}`}
                <br />
              </span>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <Box>
      {!props.chartData && (
        <Box textAlign="center">
          <Loader />
        </Box>
      )}
      {props.chartData && (
        <ResponsiveContainer width={props.width ? props.width : "100%"} height={props.height ? props.height : 250}>
          <ComposedChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="timestamp" tickFormatter={formatXAxis} allowDuplicatedCategory={false} />
            <YAxis dataKey="sumUSD" tickFormatter={formatYAxis} />
            {Object.values(props.chartData).map((element) => {
              return (
                <Line
                  key={element.as}
                  data={element.data}
                  type="basis"
                  dataKey={element.key}
                  isAnimationActive={false}
                  name={element.as ? element.as : element.key}
                  stroke={getColor(element)}
                  dot={false}
                  strokeWidth={2}
                />
              );
            })}
            <Tooltip content={<CustomTooltip />} />
            <Legend />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Box>
  );

  function formatYAxis(value) {
    return "$" + value.toFixed(0);
  }

  function formatXAxis(value) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Novr", "Dec"];
    const date = new Date(value.slice(0, 10));
    return months[date.getMonth()] + " " + date.getDate();
  }

  function formatLabel(value) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Novr", "Dec"];
    const date = new Date(value.slice(0, 10));
    return props.shortToolTip
      ? months[date.getMonth()] + " " + date.getDate()
      : months[date.getMonth()] + " " + date.getDate() + " " + value.slice(11, 16);
  }

  function getColor(element) {
    const index = Object.values(props.chartData).indexOf(element);
    return colors[index];
  }
}
