import NavBar from "./NavBar";

import "./Header.scss";

function Header() {
  return (
    <>
      <svg style={{ position: "absolute" }} width="0" height="0">
        <linearGradient id="secondary-gradient" x1="0" y1="0%" x2="100%" y2="0%">
          <stop stopColor="#00F9EF" offset="0%" />
          <stop stopColor="#5B7FFA" offset="100%" />
        </linearGradient>
        <linearGradient id="primary-gradient" x1="0" y1="0%" x2="100%" y2="0%">
          <stop stopColor="rgb(232, 28, 255)" offset="0%" />
          <stop stopColor="#F99E22" offset="100%" />
        </linearGradient>
      </svg>
      <header>
        <NavBar />
      </header>

    </>
  );
}

export default Header;
