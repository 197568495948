import Icon from "../../components/ControlElements/Icon/index.jsx";
import { getScanLink, cutHash, normalizeDate, coinFormat } from "../../utils/basicHelpers.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable } from "material-react-table";

const TopUsersView = (props) => {
  const [tableWidth, setTableWidth] = useState(null);
  const ref = useRef(null);
  const getwidth = () => {
    if (props.data) {
      setTableWidth(ref.current.offsetWidth);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", getwidth);
    getwidth();
    return () => window.removeEventListener("resize", getwidth);
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "amount",
        header: "Amount",
      },
      {
        accessorKey: "link",
        header: "Link",
      },
    ],
    []
  );
  if (props.data) {
    const users = props.data.map((item) => {
      return {
        address: tableWidth == 606 ? item.address : cutHash(item.address),
        amount: props.type == "deposited" ? coinFormat(item.deposited, { slug: props.project.token.slug }) : coinFormat(item.gained, { slug: props.project.token.slug }),
        link: (
          <a href={getScanLink(props.project.network.slug) + "address/" + item.address} target="_blank">
            <Icon type="link" />
          </a>
        ),
      };
    });
    return (
      <div className="users-table" ref={ref}>
        <MaterialReactTable
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={true}
          enableSorting={false}
          enableBottomToolbar={true}
          enableTopToolbar={false}
          columns={columns}
          data={users}
        />
      </div>
    );
  }
};

export default TopUsersView;
