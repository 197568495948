import Icon from "../../components/ControlElements/Icon/index.jsx";
import config from "../../config.js";
function TableRow(props) {
  console.log(props)
  return (
    <tr>
      <td dangerouslySetInnerHTML={{ __html: props.data.changes }}></td>
      <td>
        {props.data.related_project != 0 &&
          <a href={config.thisURL + "/project/" + props.data.related_project}><Icon type="link" /></a>
        }
      </td>
      <td>{new Date(props.data.timestamp).toDateString().split(' ').slice(1).join(' ')}</td>
    </tr>
  )
}
export default TableRow;