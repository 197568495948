import { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Icon from "../../components/ControlElements/Icon/index.jsx";
import { getScanLink, cutHash, normalizeDate, coinFormat, widthLowerThan } from "../../utils/basicHelpers.js";


function TransactionsView(props) {
  const [tableWidth, setTableWidth] = useState(null);
  const ref = useRef(null);
  const getwidth = () => {
    setTableWidth(ref.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getwidth);
    getwidth();
    return () => window.removeEventListener("resize", getwidth);
  }, [])
  if (props.transactions) {
    return (
      <div className="txs-table" ref={ref}>
        <Table hover data-bs-theme="dark" size="sm" className="mt-2 txs-table text-center">
          <thead>
            <tr>
              <th className="text-start "><span className="secondary-text-gradient">Address</span></th>
              <th width="150"><span className="secondary-text-gradient">Amount</span></th>
              <th width="80"><span className="secondary-text-gradient">Date</span></th>
              <th width="50"><span className="secondary-text-gradient">Link</span></th>
            </tr>
          </thead>
          <tbody>
            {props.transactions.map((item, i) => (
              <tr key={i}>
                <td className="text-start">{tableWidth == 606
                  ? item.address
                  : cutHash(item.address)
                }</td>
                <td>{coinFormat(item.amount, { slug: props.project.token.slug })}</td>
                <td>{normalizeDate(item.timestamp, false)}</td>
                <td>
                  <a href={getScanLink(props.project.network.slug) + "/tx/" + item.hash} target="_blank">
                    <Icon type="link" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default TransactionsView;
