import config from "../config.js";

export function getNetworkImageURL(network) {
  return config.thisURL + "/images/chains/" + network + ".png";
}
export function getTokenImageURL(token) {
  return config.thisURL + "/images/tokens/" + token + ".png";
}
export function getImageURL(name) {
  return config.thisURL + "/images/" + name;
}
export function getLogoURL(id) {
  return config.thisURL + "/images/projects/" + id + ".png";
}
export function getSecurityIcon(slug) {
  if (slug == "hazecrypto") { slug = "haze" }
  if (slug == "unaudited") { slug = "UNAUDITED" }
  return config.thisURL + "/images/security/" + slug + ".png";
}
