import "./Button.scss";
import "./../../WarningLink"
function Button(props) {
  return props.nohref
    ? <button className="button">{props.children}</button>
    : (<a className="button-wrapper" {...props}>
      <button className="button">{props.children}</button>
    </a>);
}

export default Button;
